import '@/styles/index.styl';

import i18n from '@/core/i18n';
import GlobalComponents from '@/components/_index';

import { createApp } from 'vue';

import Router from "@/core/Router";
import App from './App.vue';

const app = createApp(App);

app.use(i18n);
app.use(Router)
GlobalComponents.register(app);

app.mount('#app');
