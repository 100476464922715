import { App } from "vue";

import UIIcon from "./elements/UIIcon.vue";
import UIImage from "./elements/UIImage.vue";
import UIBtn from "./elements/UIBtn/UIBtn.vue"

import LetterByLetter from './animations/LetterByLetter.vue'
import GlitchAnimation from './animations/GlitchAnimation.vue'
import LottieAnimation from './animations/LottieAnimation.vue'

const register = (app: App<Element>): void => {
  app.component("ui-icon", UIIcon);
  app.component("ui-btn", UIBtn);
  app.component("ui-image", UIImage);
  
  app.component("glitch-animation", GlitchAnimation);
  app.component("lottie-animation", LottieAnimation);
  app.component("letter-by-letter", LetterByLetter);

};

export default { register };