export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter Theme"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a fresh start"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey!"])},
    "menu": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello World"])}
  },
  "example": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another page"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["← Go back"])}
  },
  "footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© mmp 2023"])}
  }
}