<template>
  <footer>{{ $t('footer.copyright') }}</footer>
</template>

<style lang="stylus" scoped>
footer
  position fixed
  bottom 0
  left 0
  width 100%
  height auto
  z-index 1
  padding rem(16) rem(32)
  text-align right

</style>