<template>
  <div class="NotFoundPage">
    <h1 class="h1 secondary-font">404</h1>
    <router-link to="/">{{ $t('example.back') }}</router-link>
  </div>
</template>

<style lang="stylus" scoped>
.NotFoundPage
  height 100vh
  width 100%
  background var(--black)
  color var(--white)
  display flex
  justify-content center
  align-items center
  flex-direction column
  gap rem(16)

</style>