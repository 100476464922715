import { createRouter, createWebHistory } from "vue-router"

import HomePage from '@/pages/HomePage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'

const Router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTE_BASE),
  routes: [
    {
      name: 'Home',
      path: '/',
      component: HomePage,
    },
    {
      name: '404',
      path: '/:pathMatch(.*)*',
      component: NotFoundPage
    }
  ],
})

export default Router;