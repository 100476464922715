export function requestAccessTokenGameCenter(isIos: boolean, isAndroid: boolean, refresh?: boolean): Promise<string> {
  if (!isIos && !isAndroid) {
    console.error("Not in the native app");
    return null
  }
  if (isAndroid) {
    window.Android.requestAccessTokenGameCenter();
  } else if (isIos) {
    window.webkit.messageHandlers.requestAccessTokenGameCenter.postMessage({ refresh });
  }

  return new Promise((resolve, reject) => {
    window.sendAccessTokenGameCenter = (token: string, error: string) => {
      if (error && error !== "") return reject(error)
      resolve(token)
    }
  })
}

export function requestWLDomain(isIos: boolean, isAndroid: boolean): Promise<string> {
  if (isAndroid) {
    window.Android.requestWLDomain();
  } else if (isIos) {
    window.webkit.messageHandlers.requestWLDomain.postMessage(null);
  }

  return new Promise((resolve, reject) => {
    window.sendWLDomain = (domain: string, error) => {
      if (error && error.length > 0) return reject(error);
      resolve(domain);
    };
  });
}